import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { decode } from 'html-entities';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from 'brightsky-3';
import { useCountryTranslation } from 'brightsky-3';

const initialState = {
    guides: [],
    guideId: 0,
    guideName: null,
};

export const GuidesContext = createContext(initialState);

export const GuidesContextProvider = ({ children }) => {
    const { l, n } = useCountryTranslation();
    const [currentGuides, setCurrentGuides] = useState([]);

    useEffect(() => {
        const fetchGuidesData = async () => {
            const cachedGuides = await AsyncStorage.getItem(StorageKeys.GuideKey);
            const guides = JSON.parse(cachedGuides);
            setCurrentGuides(guides);
        };
        fetchGuidesData();
    }, []);

    const handleGetGuide = (currentId, currentBinding) => {
        if (!currentGuides) return {};

        const langNode = currentGuides[l];
        const hasLang = langNode != null;
        let guide = hasLang ? langNode[n][currentBinding] : {};

        if (guide && guide.Guides && guide.Guides.length > 0) {
            const nextGuide = guide.Guides[currentId + 1];
            const nextGuideTitle = nextGuide ? nextGuide.Title : null;

            guide = {
                title: guide.Title ? decode(guide.Title) : guide.Title,
                description: guide.Description ? decode(guide.Description) : guide.Description,
                guide: guide.Guides[currentId],
                nextGuideTitle: nextGuideTitle ? decode(nextGuideTitle) : nextGuideTitle,
                isEnd: currentId >= guide.Guides.length - 1,
                endLinks: guide.EndLinks,
                guideName: currentBinding,
            };
        } else if (guide) {
            guide = {
                title: guide.Title,
                description: guide.Description,
                isEnd: true,
                endLinks: guide.EndLinks,
            };
        }

        return guide;
    };

    return (
        <GuidesContext.Provider
            value={{
                guides: currentGuides,
                getGuide: handleGetGuide,
            }}
        >
            {children}
        </GuidesContext.Provider>
    );
};

GuidesContextProvider.propTypes = {
    children: PropTypes.node,
};
