import React, { useEffect } from 'react';
import HomeHero from './HomeHero';
import HomePrimaryPanel from './HomePrimaryPanel';
import OnlineSafetyPanel from './OnlineSafetyPanel';
import HomeGuidePanel from './HomeGuidePanel';
import './css/homepage.less';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { useParams } from 'react-router-dom';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';

const HomePage = () => {
    const c = useActiveCountry();
    const { setDocumentTitle } = useDocumentTitleContext();
    const { consId } = useParams();
    const { overrideConsumer } = useWebAnalyticsContext();

    useEffect(() => {
        overrideConsumer(consId);
    }, [consId]);

    useEffect(() => {
        setDocumentTitle('Bright Sky: You are not alone: practical support and information on how to respond to domestic abuse.');
    }, [consId, setDocumentTitle]);

    return (
        <div className="home">
            <HomeHero />
            <HomePrimaryPanel />
            <OnlineSafetyPanel />
            <HomeGuidePanel />
        </div>
    );
};

export default HomePage;
