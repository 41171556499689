export const GuideKeys = {
    TypesOfSupportKey: 'TypesOfSupport',
    LeavingKey: 'Leaving',
    HowCanIHelpKey: 'HowCanIHelp',
    WebOnlineSafetyKey: 'WebOnlineSafety',
    SexualConsentKey: 'SexualConsent',
    StalkingHarassmentKey: 'StalkingHarassment',
    FormsOfAbuse: 'FormsOfAbuse',
    AdditionalKey: 'Additional',
};

export const buildGuideName = (name, c) => `${c}_Guide_${name}`;
