import Checkbox from 'antd/lib/checkbox/Checkbox';
import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import { Form, Spin } from 'antd';
import React, { useState } from 'react';
import BoundButton from '../shared/BoundButton';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import PagePanel from '../shared/PagePanel';
import DefaultTextArea from '../shared/DefaultTextArea';
import DefaultTextbox from '../shared/DefaultTextbox';
import DefaultDatePicker from '../shared/DefaultDatePicker';
import './css/journalform.less';
import http from 'brightsky-3/services/httpRequestService';
import useConfig from '../../config/hooks/useConfig';
import { Events, Origins, Templates } from 'brightsky-3';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import moment from 'moment';

const JournalForm = () => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const config = useConfig();
    const [form] = Form.useForm();
    const { validateFields, getFieldsError, setFieldsValue } = form;
    const [showPrompts, setShowPrompts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [usePrompts, setUsePrompts] = useState(false);
    const { logEvent } = useWebAnalyticsContext();
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [incidentDate, setDate] = useState(new Date());
    const [whatHappened, setwhatHappened] = useState('');
    const [howDidItMakeYouFeel, sethowDidItMakeYouFeel] = useState('');

    const labelPromptCheckbox = decode(ct('Label_JournalPrompt.Text'));

    const labelEmail = decode(ct('Label_Email.Text'));
    const placeholderEmail = decode(ct('Placeholder_SecureEmail.Text'));

    const labelMessage = decode(ct('Label_TypeAMessage.Text'));
    const placeholderMessage = decode(ct('Placeholder_TypeAMessage.Text'));

    const labelDate = decode(ct('Label_IncidentDate.Text'));

    const labelWhat = decode(ct('Label_WhatHappened.Text'));
    const placeholderWhat = decode(ct('Placeholder_WhatHappened.Text'));

    const labelHow = decode(ct('Label_HowDidItMakeYouFeel.Text'));
    const placeholderHow = decode(ct('Placeholder_HowDidItMakeYouFeel.Text'));

    const handleShowPromptsClick = () => {
        setShowPrompts(!showPrompts);
        setUsePrompts(promptOn => !promptOn);
    };

    const handleEmail = event => {
        setEmail(event.target.value);
    };

    const handleMessage = event => {
        setMessage(event.target.value);
    };

    const handlewhatHappened = event => {
        setwhatHappened(event.target.value);
    };

    const handlehowDidItMakeYouFeel = event => {
        sethowDidItMakeYouFeel(event.target.value);
    };

    const onFinish = entry => {
        validateFields();
        logEvent(Events.JournalSubmit, Origins.Journal, Templates.journal.submit(usePrompts));
        const validationErrors = Object.values(getFieldsError());
        if (!validationErrors.find(e => e === undefined)) {
            const journalEntry = { ...entry };
            journalEntry.country = c;
            journalEntry.languageCode = l;

            http.post(
                `${config.apiUrl}Journal`,
                journalEntry,
                () => {
                    setLoading(false);
                    setFieldsValue({
                        email: null,
                        message: null,
                        incidentDate: null,
                        whatHappened: null,
                        howDidItMakeYouFeel: null,
                    });
                    setSuccess(ct('Text_JournalSubmitSuccess.Text'));
                    setTimeout(() => {
                        setSuccess('');
                    }, 5000);
                },
                () => {
                    setLoading(false);
                    setError('Failed to create journal entry... please try again');
                }
            );
        }
    };
    const dateToday = moment(new Date().toLocaleDateString());
    return (
        <PagePanel background="#FFFFFF">
            <div className="journal-form">
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Form.Item valuePropName="checked" name="usePrompts">
                        <Checkbox value={usePrompts} onChange={handleShowPromptsClick}>
                            {labelPromptCheckbox}
                        </Checkbox>
                    </Form.Item>

                    <Form.Item label={labelEmail} name="email" validateTrigger="onBlur" rules={[{ type: 'email', required: true }]}>
                        <DefaultTextbox value={email} onChange={handleEmail} placeholder={placeholderEmail} />
                    </Form.Item>

                    {!showPrompts && (
                        <Form.Item label={labelMessage} name="message">
                            <DefaultTextArea value={message} onChange={handleMessage} placeholder={placeholderMessage} />
                        </Form.Item>
                    )}

                    {showPrompts && (
                        <>
                            <Form.Item initialValue={dateToday} label={labelDate} name="incidentDate">
                                <DefaultDatePicker
                                    value={incidentDate}
                                    onChange={selectedDate => {
                                        setDate(new Date(selectedDate));
                                    }}
                                />
                            </Form.Item>
                            <Form.Item label={labelWhat} name="whatHappened">
                                <DefaultTextArea value={whatHappened} onChange={handlewhatHappened} placeholder={placeholderWhat} />
                            </Form.Item>
                            <Form.Item label={labelHow} name="howDidItMakeYouFeel">
                                <DefaultTextArea value={howDidItMakeYouFeel} onChange={handlehowDidItMakeYouFeel} placeholder={placeholderHow} />
                            </Form.Item>
                        </>
                    )}

                    <Form.Item>
                        {success != null && <h3>{success}</h3>}
                        {error != null && <div style={{ color: 'red' }}>{error}</div>}

                        {!loading && (
                            <BoundButton
                                type="primary"
                                binding="Button_SubmitEntry.Text"
                                tooltipBinding="Web_Tooltip_SubmitEntry.Text"
                                htmlType="submit"
                            />
                        )}
                        {loading && <Spin />}
                    </Form.Item>
                </Form>
            </div>
        </PagePanel>
    );
};

export default JournalForm;
