import React, { useEffect, useState } from 'react';
import { decode } from 'html-entities';
import { Spin } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import useLocationSearchContext from './hooks/useLocationSearchContext';
import PagePanel from '../shared/PagePanel';
import BackToTop from '../shared/BackToTop';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import LocationResult from './LocationResult';
import './css/locatesupportsearch.less';
import './css/locatesupportpage.less';
import useAppSettings from '../shared/hooks/useAppSettings';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';

const NationalHelplinesPage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { loading, getNationalHelplines } = useLocationSearchContext();
    const { setDocumentTitle } = useDocumentTitleContext();
    const [results, setResults] = useState([]);
    const [filters, setFilters] = useState([]);
    const { appSettings } = useAppSettings();

    // TODO: Add filter support

    const nationalHelpLines = results ? results.filter(r => r.isNationalHelpLine) : [];

    const headLocate = decode(ct('Page_Helplines.Title'));
    const textLocate = decode(ct('Page_Helplines.Content'));

    useEffect(() => {
        setDocumentTitle('National Helplines. Your National Resources: Bright Sky');
    }, [setDocumentTitle]);

    useEffect(() => {
        getNationalHelplines(filters, r => {
            setResults(r);
        });
    }, [setResults]);

    return (
        <PagePanel background="#FFFFFF">
            <div style={{ margin: '10px' }} className="locate-support-page">
                <div className="locate-support-search">
                    <div className="page-title">
                        <h3>{headLocate}</h3>
                        <p dangerouslySetInnerHTML={{ __html: textLocate }}></p>
                    </div>
                </div>

                {loading && (
                    <div className="map-loading">
                        <Spin />
                    </div>
                )}

                {!loading && (
                    <div className="locate-support-helplines">
                        <div className="list">
                            {nationalHelpLines.map((r, i) => (
                                <LocationResult key={i.toString()} result={r} tagsEnabled={appSettings?.locationFiltering} />
                            ))}
                        </div>
                        <BackToTop />
                    </div>
                )}
            </div>
        </PagePanel>
    );
};

export default NationalHelplinesPage;
