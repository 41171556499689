import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import EducationResourcesPage from '../education/EducationResourcesPage';
import IsItAbusePage from '../isitabuse/IsItAbusePage';
import AbuseAdvicePage from '../abuseadvice/AbuseAdvicePage';
import OnlineSafetyPage from '../onlinesafety/OnlineSafetyPage';
import ConfigPage from '../home/ConfigPage';
import HomePage from '../home/HomePage';
import useConfigContext from '../shared/hooks/useConfigContext';
import TopNavigation from './TopNavigation';
import LocateSupportPage from '../locatesupport/LocateSupportPage';
import NationalHelplinesPage from '../locatesupport/NationalHelplinesPage';
import Footer from './Footer';
import PrivacyPolicyPage from '../privacy/PrivacyPolicyPage';
import AboutPage from '../about/AboutPage';
import FeedbackPage from '../feedback/FeedbackPage.js';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';

const Routes = () => {
    const location = useLocation();
    const history = useHistory();
    const { hasConfirmedConfig } = useConfigContext();
    const { documentTitle } = useDocumentTitleContext();
    const [showTitleAlert, setShowTitleAlert] = useState(false);

    const onConfigPage = location.pathname === '/';
    const configComplete = !hasConfirmedConfig && !onConfigPage;

    useEffect(() => {
        if (configComplete) {
            history.push('/');
        }
    }, [location]);

    useEffect(() => {
        history.listen(() => {
            document.getElementById('headerPrimaryNav').focus();
        });
    }, []);

    useEffect(() => {
        setShowTitleAlert(true);
        setTimeout(() => {
            setShowTitleAlert(false);
        }, 500);
    }, [documentTitle]);

    return (
        <>
            {showTitleAlert && (
                <div style={{ position: 'absolute', top: -200 }} aria-live="polite" aria-atomic="true" role="alert">
                    {`Navigated to page ${documentTitle}`}
                </div>
            )}
            {!onConfigPage && <TopNavigation />}

            <main>
                <Route exact path="/" component={ConfigPage} />
                <Route exact path="/:lang/home" component={HomePage} />
                <Route path="/:lang/home/:consId" component={HomePage} />
                <Route path="/:lang/is-it-abuse" component={IsItAbusePage} />
                <Route path="/:lang/abuse-advice" component={AbuseAdvicePage} />
                <Route path="/:lang/education-resources" component={EducationResourcesPage} />
                <Route path="/:lang/online-safety-guide" component={OnlineSafetyPage} />
                <Route path="/:lang/locate-support/:location" component={LocateSupportPage} />
                <Route path="/:lang/national-helplines" component={NationalHelplinesPage} />
                <Route path="/:lang/feedback" component={FeedbackPage} />
                <Route path="/:lang/privacy" component={PrivacyPolicyPage} />
                <Route path="/:lang/about" component={AboutPage} />
            </main>

            {!onConfigPage && <Footer />}
        </>
    );
};

export default Routes;
