import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import './css/boundbutton.less';
import useActiveCountry from './hooks/useActiveCountry';
import { decode } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BoundButton = ({
    binding,
    text,
    type = 'primary',
    onClick,
    disabled,
    icon,
    iconRight,
    tooltipBinding,
    className = 'addtl-class',
    htmlType,
    ariaLabel,
    button,
    style,
}) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const iconElement =
        icon != null ? (
            <span style={{ paddingRight: 10 }}>
                <FontAwesomeIcon icon={icon} />
            </span>
        ) : null;
    const tooltipText = decode(ct(tooltipBinding));
    const buttonText = binding != null ? decode(ct(binding)) : text;
    //const ariaLabelPhone = `${result.name}`

    // TODO: Tooltips have been disabled until there is time/budget to
    //       create the translations needed to populate their values.

    return (
        // <Tooltip title={tooltipText}>
        <Button
            style={style}
            icon={iconElement}
            className={`bound-button ${type} ${className}`}
            disabled={disabled}
            onClick={onClick}
            htmlType={htmlType}
            aria-label={ariaLabel}
            role={button}
        >
            {buttonText}
            {iconRight && <FontAwesomeIcon style={{ marginLeft: 12 }} icon={iconRight} />}
        </Button>
        // </Tooltip>
    );
};

BoundButton.propTypes = {
    binding: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary']),
    onClick: PropTypes.func,
    icon: PropTypes.any,
    iconRight: PropTypes.any,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.string,
    htmlType: PropTypes.string,
    searchResult: PropTypes.string,
    ariaLabel: PropTypes.string,
    button: PropTypes.string,
};

export default BoundButton;
