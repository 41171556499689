import { useContext } from 'react';
import { AppSettingsContext } from '../contexts/AppSettingsContext';

const useAppSettingsContext = () => {
    const context = useContext(AppSettingsContext);
    if (!context) {
        throw new Error('useAppSettingsContext must be used within a ConfigContextProvider');
    }
    return context;
};

export default useAppSettingsContext;
