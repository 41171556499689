import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from 'antd/lib/layout/layout';
import { i18nService } from 'brightsky-3';
import useConfig from './config/hooks/useConfig';
import i18n from 'i18next';
import { Spin } from 'antd';
import Routes from './features/navigation/Routes';
import { ContentContextProvider } from './features/shared/contexts/ContentContext';
import { ConfigContextProvider } from './features/shared/contexts/ConfigContext';
import { LocationSearchContextProvider } from './features/locatesupport/contexts/LocationSearchContext';
import { QuestionnaireContextProvider } from './features/isitabuse/contexts/QuestionnaireContext';
import { WebAnalyticsContextProvider } from './features/shared/contexts/WebAnalyticsContext';
import { DocumentTitleContextProvider } from './features/shared/contexts/DocumentTitleContext';
import { getCountrySubdomain, getLangFromUrl } from './util/functions';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { GuidesContextProvider } from './features/shared/contexts/GuidesContext';
import 'antd/dist/antd.css';
import './theme/vars.less';
import { appInsightsPlugin } from './util/appInsights';
import FocusVisible from './features/shared/FocusVisible';
import './App.css';
import useAppSettings from './features/shared/hooks/useAppSettings';
import { AppSettingsContextProvider } from './features/shared/contexts/AppSettingsContext';
require('./assets/images/favicon.ico');

function App() {
    const appSettings = useAppSettings();
    const [loadingTranslations, setLoadingTranslations] = useState(true);
    const [applyingLanguage, setApplyingLanguage] = useState(true);
    const config = useConfig();

    useEffect(() => {
        if (appSettings.loaded) {
            appSettings.redirectToLangUrl();
            setApplyingLanguage(false);
        }
    }, [appSettings]);

    // NOTE: We need to set a default translation country to use before the country is selected
    //       So that we're able to pull the config page translations.
    //       After selection, the proper country code is code during translation.
    const sCountry = getCountrySubdomain();
    const defaultCountryCode = sCountry == null ? null : sCountry;
    const hasCountryInUrl = sCountry !== null;
    const loadingTranslationsTimeout = hasCountryInUrl ? 5000 : 1000;
    const urlLang = getLangFromUrl();
    const ready = !loadingTranslations && !applyingLanguage;

    const setLang = () => {
        if (urlLang) {
            i18n.changeLanguage(urlLang);
            document.children[0].setAttribute('lang', urlLang);
        }
    };

    useEffect(() => {
        i18nService.LoadWebTranslations(config.apiUrl, defaultCountryCode, urlLang, translations => {
            for (const [key, value] of Object.entries(translations)) {
                i18n.addResourceBundle(key, 'translation', value.translation);
            }

            setLang();
            setTimeout(() => {
                setLoadingTranslations(false);
                setLang();
            }, loadingTranslationsTimeout);
        });
    }, [i18n]);

    return (
        <Layout className="layout">
            <FocusVisible className="js-focus-visible focus-visible">
                <BrowserRouter>
                    <AppSettingsContextProvider>
                        <ContentContextProvider>
                            <ConfigContextProvider>
                                <WebAnalyticsContextProvider>
                                    <GuidesContextProvider>
                                        <LocationSearchContextProvider>
                                            <DocumentTitleContextProvider>
                                                <QuestionnaireContextProvider>
                                                    {ready && <Routes />}
                                                </QuestionnaireContextProvider>
                                            </DocumentTitleContextProvider>
                                        </LocationSearchContextProvider>
                                    </GuidesContextProvider>
                                </WebAnalyticsContextProvider>
                            </ConfigContextProvider>
                        </ContentContextProvider>
                    </AppSettingsContextProvider>

                    {!ready &&
                        <div className="flex-center">
                            <Spin style={{ marginTop: 50, height: '100vh' }} size="large" />
                        </div>
                    }
                </BrowserRouter>
            </FocusVisible>
        </Layout>
    );
}

export default withAITracking(appInsightsPlugin, App);
