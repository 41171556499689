import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { Events, Templates, useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import DefaultSelect from '../shared/DefaultSelect';
import CountrySelect from '../shared/CountrySelect';
import useConfigContext from '../shared/hooks/useConfigContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-solid-svg-icons';
import './css/navigation.less';
import './css/footer.less';
import useConfig from '../../config/hooks/useConfig';
import { decode } from 'html-entities';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import useAppSettings from '../shared/hooks/useAppSettings';

const { Footer: AntdFooter } = Layout;

function Footer() {
    const c = useActiveCountry();
    const config = useConfig();
    const { appSettings } = useAppSettings();
    const { languages, language, setLanguage } = useConfigContext();
    const { l, ct } = useCountryTranslation(c);
    const { logEvent } = useWebAnalyticsContext();
    const [selectedCountry, setSelectedCountry] = useState(c);
    const [cachedLanguages, setCachedLanguages] = useState([]);
    const iosInstallLink = appSettings?.iosInstallUrl;
    const androidInstallLink = appSettings?.androidInstallUrl;

    useEffect(() => {
        if (languages != null) {
            setCachedLanguages(languages);
        }
    }, [languages]);

    const onCountrySelect = newCountry => {
        logEvent(Events.SettingsChange, 'Footer_Web', Templates.settings.change('country', newCountry));
        const countryUrl = config.baseWebUri.replace('{c}', newCountry.toLowerCase());
        window.location.href = countryUrl;
        setSelectedCountry(newCountry);
    };

    const onLanguageSelect = l => {
        logEvent(Events.SettingsChange, 'Footer_Web', Templates.settings.change('language', l));
        const countryUrl = config.baseCountryChangeRedirectUrl.replace('{c}', selectedCountry.toLowerCase()).replace('{l}', l);
        window.location.href = countryUrl;
        setLanguage(l);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <AntdFooter className="footer">
            <nav className="menu header" aria-label="Footer Navigation">
                <div className="nav-icon footer-nav-icon">
                    <Link className="nav-link" style={{ marginRight: 8 }} to={`/${l}/home`}>
                        <img src={require('../../assets/images/bs-logo-white.svg')} />
                    </Link>
                    {iosInstallLink && (
                        <a ariaLabel="Install on iOS" className="ios-install" target="_blank" href={iosInstallLink}>
                            <img width={100} src={require('../../assets/images/ios-install-img.svg')} />
                        </a>
                    )}
                    {androidInstallLink && (
                        <a className="android-install" ariaLabel="Install on Android" target="_blank" href={androidInstallLink}>
                            <img width={120} src={require('../../assets/images/android-install-img.png')} />
                        </a>
                    )}
                </div>
                <div className="main-menu">
                    <div className="right-menu">
                        <div className="country-select footer-menu">
                            <FontAwesomeIcon className="icon footer-icon" icon={faFlag} color="#FFF" />
                            <CountrySelect
                                width={150}
                                ariaLabel="Select Country"
                                allowClear={false}
                                onChange={onCountrySelect}
                                value={selectedCountry}
                            />
                        </div>
                        <div className="language-select footer-menu">
                            <DefaultSelect
                                ariaLabel={'Select Language'}
                                width={100}
                                allowClear={false}
                                onChange={onLanguageSelect}
                                items={cachedLanguages}
                                value={language}
                            />
                        </div>
                        <div className="footer-menu">
                            <Link className="feedback-link" to={`/${l}/feedback`} onClick={scrollToTop}>
                                {decode(ct('Web_Link_Feedback.Text'))}
                            </Link>
                        </div>
                        <div className="footer-menu">
                            <Link className="about-link" to={`/${l}/about`} onClick={scrollToTop}>
                                {decode(ct('Web_Link_About.Text'))}
                            </Link>
                        </div>
                        <div className="footer-menu">
                            <Link className="privacy-link" to={`/${l}/privacy`} onClick={scrollToTop}>
                                {decode(ct('Web_Link_PrivacyPolicy.Text'))}
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </AntdFooter>
    );
}

export default Footer;
