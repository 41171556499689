import React, { useEffect, useState } from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarker, faHeadset } from '@fortawesome/pro-solid-svg-icons';
import { decode } from 'html-entities';
import { useCountryTranslation } from 'brightsky-3';
import { Spin } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import useLocationSearchContext from './hooks/useLocationSearchContext';
import useAppSettings from '../shared/hooks/useAppSettings';
import DefaultTextbox from '../shared/DefaultTextbox';
import PagePanel from '../shared/PagePanel';
import BoundButton from '../shared/BoundButton';
import BackToTop from '../shared/BackToTop';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import LocationResult from './LocationResult';
import useConfig from '../../config/hooks/useConfig';
import MapMarker from './MapMarker';
import TagFilters from './TagFilters';
import './css/locatesupportsearch.less';
import './css/locatesupportpage.less';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';

const LocateSupportPage = () => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const history = useHistory();
    const config = useConfig();
    const { loading, runSearch, locationSearch, setLocationSearch, mapRegion } = useLocationSearchContext();
    const { setDocumentTitle } = useDocumentTitleContext();
    const { location } = useParams();
    const [results, setResults] = useState([]);
    const [filters, setFilters] = useState([]);
    const [filtersOpen, setFiltersOpen] = useState();
    const { appSettings } = useAppSettings();

    const physicalLocations = results ? results.filter(r => !r.isNationalHelpLine) : [];
    const nationalHelpLines = results ? results.filter(r => r.isNationalHelpLine) : [];

    const tbPlaceholder = decode(ct('Label_EnterLocation.Text'));
    const headLocate = decode(ct('Page_Web_LocateSupport.Title'));
    const textLocate = decode(ct('Page_Web_LocateSupport.Content'));

    useEffect(() => {
        setDocumentTitle('Locate Support. Nearby available support locations: Bright Sky');
    }, [setDocumentTitle]);

    useEffect(() => {
        setLocationSearch(location);
        handleSearch(location);
    }, [location, setLocationSearch]);

    const handleSearch = (l, newFilters) => {
        const calcFilters = newFilters ? newFilters : filters;
        const calcLocation = l ? l : locationSearch;
        runSearch(
            calcLocation,
            r => {
                setResults(r);
            },
            calcFilters
        );
    };

    const handleUpdateSearch = newFilters => {
        setFilters(newFilters);
        handleSearch(locationSearch, newFilters);
    };

    const handleSearchClick = () => {
        handleSearch();
    };

    const handleNationalHelplinesClick = () => {
        history.push(`/${l}/national-helplines`);
    };

    const handleOpenFilters = () => {
        setFiltersOpen(true);
    };

    const handleCloseFilters = () => {
        setFiltersOpen(false);
    };

    const handleLocationUpdate = ctrl => {
        setLocationSearch(ctrl.target.value);
    };

    const mapDetails = {
        center: {
            lat: mapRegion?.latitude ?? 0,
            lng: mapRegion?.longitude ?? 0,
        },
        zoom: 11,
    };

    return (
        <PagePanel background="#FFFFFF">
            <div className="locate-support-page">
                <div className="locate-support-search">
                    <div className="page-title">
                        <h3>{headLocate}</h3>
                        <p dangerouslySetInnerHTML={{ __html: textLocate }}></p>
                    </div>

                    <h4>{decode(ct('Label_ShowingResultsFor.Text'))}</h4>

                    <div className="locate-support support-locate">
                        <div className="search-box locate-support-box">
                            <DefaultTextbox icon={faSearch} placeholder={tbPlaceholder} value={locationSearch} onChange={handleLocationUpdate} />
                        </div>
                        <div className="search-button support-search-button">
                            <BoundButton
                                className="support-search-button"
                                onClick={handleSearchClick}
                                icon={faMapMarker}
                                binding="Button_LocateSupport.Text"
                                tooltipBinding="Web_Tooltip_LocateSupport.Text"
                            />
                        </div>
                    </div>

                    <div className="national-helplines-button locate-helplines-button">
                        <BoundButton
                            className="support-search-button"
                            id="test"
                            icon={faHeadset}
                            type="secondary"
                            binding="Nav_AbuseAdvice_Helplines.Text"
                            tooltipBinding="Web_Tooltip_NationalHelplines.Text"
                            onClick={handleNationalHelplinesClick}
                        />
                    </div>
                </div>
                {loading && (
                    <div className="map-loading">
                        <Spin />
                    </div>
                )}
                {!loading && (
                    <div className="locate-support-map" style={{ marginLeft: '10px' }}>
                        <div className="map-results">
                            <div className="text">
                                {results.length} {decode(ct('Text_Results.Text'))}
                            </div>
                            <div className="button">
                                {appSettings?.locationFiltering && (
                                    <BoundButton
                                        type="primary"
                                        binding="Text_FilterResults.Text"
                                        tooltipBinding="Web_Tooltip_FilterResults.Text"
                                        onClick={handleOpenFilters}
                                    />
                                )}
                                <TagFilters filters={filters} visible={filtersOpen} onUpdated={handleUpdateSearch} onClose={handleCloseFilters} />
                            </div>
                        </div>

                        <div className="google-map">
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: config.googleMapsKey }}
                                defaultCenter={mapDetails.center}
                                defaultZoom={mapDetails.zoom}
                            >
                                {physicalLocations
                                    .filter(p => p.displayOnMap === true)
                                    .map((p, i) => {
                                        return <MapMarker key={i.toString()} lat={p.location.lat} lng={p.location.lng} text={i + 1} />;
                                    })}
                            </GoogleMapReact>
                        </div>
                    </div>
                )}
                {!loading && (
                    <div className="locate-support-results">
                        {physicalLocations.map((r, i) => (
                            <LocationResult number={i + 1} key={i.toString()} result={r} tagsEnabled={appSettings?.locationFiltering} />
                        ))}
                        <div className="bottom">
                            <BackToTop />
                        </div>
                    </div>
                )}
                {!loading && (
                    <div className="locate-support-helplines">
                        <h3 className="page-title">{decode(ct('Nav_AbuseAdvice_Helplines.Text'))}</h3>
                        <div className="list">
                            {nationalHelpLines.map((r, i) => (
                                <LocationResult number={i + 1} key={i.toString()} result={r} tagsEnabled={appSettings?.locationFiltering} />
                            ))}
                        </div>
                        <BackToTop />
                    </div>
                )}
            </div>
        </PagePanel>
    );
};

export default LocateSupportPage;
