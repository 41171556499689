import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { StorageKeys } from 'brightsky-3';
import { decode } from 'html-entities';
import { Player } from 'video-react';
import useContentContext from './hooks/useContentContext';
import useConfig from '../../config/hooks/useConfig';
import './css/boundaccordion.less';
import '../../../node_modules/video-react/dist/video-react.css';
import useConfigContext from './hooks/useConfigContext';
import BoundButton from './BoundButton';
import BoundLinkButton from './BoundLinkButton';

const BoundGuideAccordion = ({ binding }) => {
    const { Panel } = Collapse;
    const { language } = useConfigContext();
    const { content, getContent, loaded } = useContentContext();
    const [items, setItems] = useState([]);
    const [showLearnMore, setShowLearnMore] = useState(false);
    const config = useConfig();

    useEffect(() => {
        const hasContent = content != null && content[StorageKeys.GuideKey] != null;
        if (hasContent) {
            const guideContent = getContent(StorageKeys.GuideKey, binding);
            const innerContent = guideContent?.Guides ? guideContent?.Guides : [];
            setItems(innerContent);
        }
    }, [content, loaded, setItems, language]);

    const onShowMoreClick = () => {
        setShowLearnMore(!showLearnMore);
    };
    const hideLearnMore = () => {
        setTimeout(() => {
            setShowLearnMore(false);
        }, 175);
    };

    const getMediaUrl = asset => {
        return asset?.Media != null && asset?.Media?.length > 0 ? `${config.assetUrl}${asset?.Media[0]}` : null;
    };

    const mappedItems =
        items && items.length > 0 ? (
            items.map((guide, i) => {
                const image = guide?.MediaType === 'Image' || guide?.MediaType === 'SVG' ? getMediaUrl(guide) : null;
                const video = guide?.MediaType === 'Video' ? getMediaUrl(guide) : null;
                const videoPlayer = <Player playsInline src={video} />;
                const svgBg = guide?.MediaType === 'SVG' ? 'brightness(0%)' : '';
                const hasLearnMore = guide?.LearnMore != null && guide?.LearnMore != '';

                return (
                    <Panel key={i.toString()} header={decode(guide.Title)}>
                        {image != null && <img style={{ filter: svgBg, marginBottom: 12 }} src={image} alt={decode(guide.Title)} height={200} />}
                        {video != null && videoPlayer}
                        <br />
                        <p dangerouslySetInnerHTML={{ __html: decode(guide.Content) }}></p>
                        <br />
                        {!showLearnMore && hasLearnMore && <BoundLinkButton binding="Text_ShowMore.Text" onClick={onShowMoreClick} />}
                        {showLearnMore && hasLearnMore && <p dangerouslySetInnerHTML={{ __html: decode(guide.LearnMore) }}></p>}
                        {showLearnMore && hasLearnMore && <BoundLinkButton binding="Text_ShowLess.Text" onClick={onShowMoreClick} />}
                    </Panel>
                );
            })
        ) : (
            <></>
        );

    return (
        <Collapse accordion onChange={hideLearnMore}>
            {mappedItems}
        </Collapse>
    );
};

BoundGuideAccordion.propTypes = {
    binding: PropTypes.string.isRequired,
};

export default BoundGuideAccordion;
