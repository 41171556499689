import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import BoundGuideTiles from '../shared/BoundGuideTiles';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import PagePanel from '../shared/PagePanel';

const EducationResourcesPage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { setDocumentTitle } = useDocumentTitleContext();

    const headResources = decode(ct('Page_Resources.Title'));
    const textResources = decode(ct('Page_Resources.Content'));

    useEffect(() => {
        setDocumentTitle('Forms of Abuse. Short definitions: Bright Sky');
    }, [setDocumentTitle]);

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />

            <PagePanel id="skip-to" background="#FFFFFF">
                <BoundGuideTiles binding="Guide_FormsOfAbuse" />
            </PagePanel>
            <PagePanel className="resource-links">
                <div className="page-title">
                    <a id="resources" />
                    <h3>{headResources}</h3>
                </div>
                <p dangerouslySetInnerHTML={{ __html: textResources }} />
            </PagePanel>
        </div>
    );
};

export default EducationResourcesPage;
