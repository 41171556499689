import { useEffect, useState } from 'react';
import useActiveCountry from './useActiveCountry';
import http from 'brightsky-3/services/httpRequestService';
import useConfig from '../../../config/hooks/useConfig';
import { getCountrySubdomain, getLangFromUrl } from '../../../util/functions';

const useAppSettings = () => {
    const config = useConfig();
    const c = useActiveCountry();
    const [appSettings, setAppSettings] = useState();
    const [loaded, setLoaded] = useState(false);

    const redirectToLangUrl = () => {
        const localizedUrl = getLangFromUrl();
        const urlhasLang = localizedUrl !== 'undefined' && localizedUrl !== null && localizedUrl !== '';
        if (!urlhasLang) {
            const appSettingsLang = appSettings.defaultLanguageCode;
            if (appSettingsLang) {
                window.location.href = `/${appSettingsLang}/home`;
            } else {
                window.history.pushState('', '', '/en/home');
            }
        }
    };

    const getAppSettings = async (countryCode) => {
        const countryInUrl = getCountrySubdomain();
        const countryToUse = (countryCode == null || countryCode === 'VF') && countryInUrl ? countryInUrl : countryCode;
        const countryFilter = `?country=${countryToUse}`;
        await http.get(
            `${config.apiUrl}content/appsettings${countryFilter}`,
            result => {
                const asContent = result.data;
                const mappedAppSettings = {
                    defaultLanguageCode: asContent?.DefaultLanguageCode?.iv,
                    exitUrl: asContent?.WebsiteExitUrl?.iv,
                    webHeroImage: asContent?.WebHeroImage?.iv,
                    locationFiltering: asContent?.LocationFilteringEnabled?.iv,
                    iosInstallUrl: asContent?.IosInstallUrl?.iv,
                    androidInstallUrl: asContent?.AndroidInstallUrl?.iv,
                    findHelpSearchEnabled: asContent?.FindHelpSearchEnabled?.iv,
                    additionalGuideEnabled: asContent?.AdditionalGuideEnabled?.iv,
                    newResourcesEnabled: asContent?.NewResourcesEnabled?.iv,
                };
                setAppSettings(mappedAppSettings);
                setLoaded(true);
            },
            err => {
                console.warn('useAppSettings: Unable to retrieve data', config.apiUrl, err);
            }
        );
    };

    useEffect(() => {
        (async () => {
            if (c) { await getAppSettings(c); }
        })();
    }, [c]);

    return { appSettings, getAppSettings, redirectToLangUrl, loaded };
};

export default useAppSettings;
