import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import { useEffect, useState } from 'react';
import useContentContext from '../../shared/hooks/useContentContext';
import useActiveCountry from '../../shared/hooks/useActiveCountry';
import http from 'brightsky-3/services/httpRequestService';
import useConfig from '../../../config/hooks/useConfig';
import useWebAnalyticsContext from '../../shared/hooks/useWebAnalyticsContext';
import { Events, Origins, Templates } from 'brightsky-3';

const generateFeedbackRatings = () => {
    const feedbackRange = [];
    feedbackRange.push({ label: '1', value: 1, defaultChecked: false });
    feedbackRange.push({ label: '2', value: 2, defaultChecked: false });
    feedbackRange.push({ label: '3', value: 3, defaultChecked: false });
    feedbackRange.push({ label: '4', value: 4, defaultChecked: false });
    feedbackRange.push({ label: '5', value: 5, defaultChecked: false });
    return feedbackRange;
};
const getSelectedValue = items => {
    let selectedValue = items.filter(i => i.checked);
    selectedValue = selectedValue && selectedValue.length > 0 ? selectedValue[0].value : null;
    return selectedValue;
};

const useFeedback = () => {
    const { content, getContent } = useContentContext();
    const { c } = useActiveCountry;
    const { ct, l } = useCountryTranslation(c);
    const [ageRangeList, setAgeRangeList] = useState();
    const [genderList, setGenderList] = useState();
    const [ethnicityGeneralList, setEthnicityGeneralList] = useState();
    const [specificEthnicityList, setSpecificEthnicityList] = useState();
    const [whiteEthnicityList, setWhiteEthnicityList] = useState();
    const [asianEthnicityList, setAsianEthnicityList] = useState();
    const [mixedEthnicityList, setMixedEthnicityList] = useState();
    const [blackEthnicityList, setBlackEthnicityList] = useState();
    const [ethnicitySpecific, setEthnicitySpecific] = useState();
    const [ethnicityGeneral, setEthnicityGeneral] = useState();
    const [ageRange, setAgeRange] = useState();
    const [gender, setGender] = useState('');
    const [useAgainValue, setUseAgainValue] = useState(generateFeedbackRatings());
    const [helpfulValue, setHelpfulValue] = useState(generateFeedbackRatings());
    const [easeOfUseValue, setEaseOfUseValue] = useState(generateFeedbackRatings());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const config = useConfig();
    const { logEvent } = useWebAnalyticsContext();
    const [selectEaseOfUse, setSelectEaseOfUse] = useState();
    const [selectHelpful, setSelectHelpful] = useState();
    const [selectUseAgain, setSelectUseAgain] = useState();

    var mapItems = items => {
        if (!items) {
            return;
        }
        return items.Items.map(item => ({ label: item.Label, value: item.Identifier }));
    };

    useEffect(() => {
        const hasLContent = content != null && content[StorageKeys.ListsKey] != null;
        if (hasLContent) {
            setAgeRangeList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_AgeRange')));
            setGenderList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_Gender')));
            setEthnicityGeneralList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_Ethnicity_General')));
            setWhiteEthnicityList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_Ethnicity_White')));
            setAsianEthnicityList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_Ethnicity_Asian')));
            setMixedEthnicityList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_Ethnicity_Mixed')));
            setBlackEthnicityList(mapItems(getContent(StorageKeys.ListsKey, 'Dropdown_Ethnicity_Black')));
            setSpecificEthnicityList(mapItems(getContent(StorageKeys.ListsKey)));
        }
    }, [
        setAgeRangeList,
        setGenderList,
        setEthnicityGeneralList,
        setWhiteEthnicityList,
        setAsianEthnicityList,
        setMixedEthnicityList,
        setBlackEthnicityList,
    ]);

    let ethnicitySpecificList = [];
    switch (ethnicityGeneral) {
        case 'white':
            ethnicitySpecificList = whiteEthnicityList;
            break;
        case 'mixed':
            ethnicitySpecificList = mixedEthnicityList;
            break;
        case 'asian':
            ethnicitySpecificList = asianEthnicityList;
            break;
        case 'black':
            ethnicitySpecificList = blackEthnicityList;
            break;
        default:
            ethnicitySpecificList = [];
            break;
    }
    const handleSetGeneralEthnicity = ethnicityGeneralList => {
        setEthnicityGeneral(ethnicityGeneralList);
        setEthnicitySpecific(ethnicitySpecificList);
    };

    const isValid = () => {
        const useAgainSelected = getSelectedValue(useAgainValue);
        const easeOfUseSelected = getSelectedValue(easeOfUseValue);
        const helpfulSelected = getSelectedValue(helpfulValue);
        const hasValue = useAgainSelected || easeOfUseSelected || helpfulSelected || ageRange || gender || ethnicitySpecific;

        return hasValue;
    };

    const handleEaseOfUse = e => {
        setSelectEaseOfUse(e.target.value);
    };

    const handleHelpful = e => {
        setSelectHelpful(e.target.value);
    };

    const handleUseAgain = e => {
        setSelectUseAgain(e.target.value);
    };

    const feedbackResponse = {
        helpfulRating: selectHelpful,
        EaseOfUseRating: selectEaseOfUse,
        useAgainRating: selectUseAgain,
        ageRange,
        gender,
        ethnicityGeneral: ethnicityGeneral,
        ethnicitySpecific: ethnicitySpecific,
    };

    const submit = () => {
        setLoading(true);
        setError();

        logEvent(Events.FeedbackSubmit, Origins.Feedback, Templates.feedback.submit(feedbackResponse));

        http.post(
            `${config.apiUrl}Feedback`,
            feedbackResponse,
            setSuccess(ct('Success_SubmittedFeedback.Text')),

            err => {
                console.log("Error submitting feedback", err);
                setLoading(false);
                setError(ct('Error_FeedbackFailed.Text'));
            }
        );
    };

    return {
        setSuccess,
        success,
        handleUseAgain,
        handleHelpful,
        handleEaseOfUse,
        submit,
        isValid,
        loading,
        lists: {
            ages: ageRangeList,
            genders: genderList,
            ethnicity: {
                general: ethnicityGeneralList,
                specific: ethnicitySpecificList,
            },
        },
        values: {
            helpful: {
                value: helpfulValue,
                set: setHelpfulValue,
            },
            easeOfUse: {
                value: easeOfUseValue,
                set: setEaseOfUseValue,
            },
            useAgain: {
                value: useAgainValue,
                set: setUseAgainValue,
            },
            age: {
                value: ageRange,
                set: setAgeRange,
            },
            gender: {
                value: gender,
                set: setGender,
            },
            ethnicity_general: {
                value: ethnicityGeneral,
                set: handleSetGeneralEthnicity,
            },
            ethnicity_specific: {
                value: ethnicitySpecific,
                set: setEthnicitySpecific,
            },
        },
    };
};
export default useFeedback;
