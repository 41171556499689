import React, { useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import useWebAnalyticsContext from '../../shared/hooks/useWebAnalyticsContext';
import { Events, Origins, Templates } from 'brightsky-3';
import { useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import { StorageKeys } from 'brightsky-3';
import AsyncStorage from '@react-native-async-storage/async-storage';

const initialState = {};

export const QuestionnaireContext = createContext(initialState);

export const QuestionnaireContextProvider = ({ children }) => {
    const { l, n } = useCountryTranslation();
    const { logEvent } = useWebAnalyticsContext();
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState([]);
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
    const [currentQuestion, setCurrentQuestion] = useState();
    const [currentQuestions, setCurrentQuestions] = useState();
    const [answers, setAnswers] = useState([]);
    const [answerNames, setAnswerNames] = useState([]);
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);

    const fetchQuestionnairesData = async () => {
        const cachedQuestionnaires = await AsyncStorage.getItem(StorageKeys.QuestionnaireContentKey);
        const questionnaires = JSON.parse(cachedQuestionnaires);
        setSelectedQuestionnaire(questionnaires);
    };

    const reset = () => {
        setCurrentQuestion();
        setCurrentQuestionIndex(0);
        setCorrectAnswerCount(0);
        setAnswers([]);
        setAnswerNames([]);
        fetchQuestionnairesData();
    };

    useEffect(() => {
        fetchQuestionnairesData();
    }, [l]);

    const handleGetQuestionnaire = (currentId, currentBinding) => {
        if (!selectedQuestionnaire) return {};

        const langNode = selectedQuestionnaire[l];
        const hasLangNode = langNode != null;
        let questionnaire = hasLangNode ? langNode[n][currentBinding] : {};

        if (questionnaire && questionnaire.Questions && questionnaire.Questions.length > 0) {
            const nextQuestion = questionnaire.Questions[currentId + 1];

            questionnaire = {
                Title: questionnaire.Title ? decode(questionnaire.Title) : questionnaire.Title,
                Questions: questionnaire.Questions,
                Question: questionnaire.Questions[currentId],
                NextQuestion: nextQuestion ? decode(nextQuestion) : nextQuestion,
                QuestionnaireName: currentBinding,
            };
        } else if (questionnaire) {
            questionnaire = {
                Title: questionnaire.Title,
            };
        }

        return questionnaire;
    };

    const startQuestionnaire = q => {
        setCurrentQuestion(q.Question);
        setCurrentQuestionnaire(q.QuestionnaireName);
        setCurrentQuestions(q.Questions);
    };

    const advanceQuestion = () => {
        const nextQuestionIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setCurrentQuestion(currentQuestions[nextQuestionIndex]);
    };

    const previousQuestion = () => {
        const nextQuestionIndex = currentQuestionIndex - 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setCurrentQuestion(currentQuestions[nextQuestionIndex]);
    };

    const isComplete = () => {
        if (currentQuestions && currentQuestionIndex && currentQuestions && currentQuestionIndex === currentQuestions.length) {
            logEvent(Events.QuestionnaireCompleteEvent, Origins.Questionnaire, Templates.questionnaire.complete(currentQuestionnaire));
            return true;
        }

        return false;
    };

    const recordAnswer = (index, answer, answerName) => {
        const newAnswers = [...answers];
        newAnswers[index] = answer;
        setAnswers(newAnswers);
        const newAnswerNames = [...answerNames];
        newAnswerNames[index] = answerName;
        setAnswerNames(newAnswerNames);
        if (index >= currentQuestionIndex) {
            advanceQuestion();
        }

        logEvent(
            Events.QuestionnaireAnswerEvent,
            Origins.QuestionnaireQuestion,
            Templates.questionnaire.answer(
                currentQuestionnaire,
                currentQuestionIndex + 1, // Question Number
                answer
            )
        );
    };

    const scoreQuestionnaire = () => {
        let correctCount = 0;
        answers.forEach((a, i) => {
            const result = a === currentQuestions[i].Answer;
            if (result) {
                correctCount += 1;
            }
        });
        setCorrectAnswerCount(correctCount);
    };

    const resetAnswers = () => {
        setAnswers([]);
        setCorrectAnswerCount(0);
    };

    return (
        <QuestionnaireContext.Provider
            value={{
                currentQuestionnaire,
                getQuestionnaire: handleGetQuestionnaire,
                currentQuestionIndex,
                setCurrentQuestionIndex,
                currentQuestion,
                setCurrentQuestion,
                answers,
                setAnswers,
                answerNames,
                setAnswerNames,
                correctAnswerCount,
                setCorrectAnswerCount,
                reset,
                startQuestionnaire,
                scoreQuestionnaire,
                previousQuestion,
                resetAnswers,
                recordAnswer,
                isComplete,
            }}
        >
            {children}
        </QuestionnaireContext.Provider>
    );
};

QuestionnaireContextProvider.propTypes = {
    children: PropTypes.node,
};
