import React, { createContext } from "react";
import PropTypes from "prop-types";
import useAppSettings from "../hooks/useAppSettings";

export const AppSettingsContext = createContext();

export const AppSettingsContextProvider = ({ children }) => {
    const { appSettings, getAppSettings, redirectToLangUrl, loaded } = useAppSettings();

    return (
        <AppSettingsContext.Provider value={{ appSettings, getAppSettings, redirectToLangUrl, loaded }}>
            {children}
        </AppSettingsContext.Provider>
    );
};

AppSettingsContextProvider.propTypes = {
    children: PropTypes.node,
};
