import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import './css/defaulttextbox.less';

const DefaultTextArea = ({ onChange, value, icon, placeholder, id, disabled = false }) => {
    const { TextArea } = Input;
    return (
        <TextArea id={id} className="default-textbox" placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} suffix={icon} />
    );
};

DefaultTextArea.propTypes = {
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
    id: PropTypes.string,
};

export default DefaultTextArea;
