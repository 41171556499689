import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import { decode } from 'html-entities';
import React, { useEffect, useState } from 'react';
import LocateSupportBanner from '../locatesupport/LocateSupportBanner';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useContentContext from '../shared/hooks/useContentContext';
import PagePanel from '../shared/PagePanel';
import './css/onlinesafetypage.less';
import BackToTop from '../shared/BackToTop';
import useConfigContext from '../shared/hooks/useConfigContext';
import useDocumentTitleContext from '../shared/hooks/useDocumentTitleContext';
import ShowMoreShowLess from '../shared/ShowMoreShowLess';
import useGuidesContext from '../shared/hooks/useGuidesContext';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import { buildGuideName, GuideKeys } from '../shared/Guides';
import { Events, Origins, Templates } from 'brightsky-3';
import TextBlock from '../shared/TextBlock';

const OnlineSafetyPage = () => {
    const c = useActiveCountry();
    const { language } = useConfigContext();
    const { content, getContent, loaded } = useContentContext();
    const { setDocumentTitle } = useDocumentTitleContext();
    const { ct } = useCountryTranslation(c);
    const [items, setItems] = useState([]);
    const [showLearnMore, setShowLearnMore] = useState(false);
    const { getGuide } = useGuidesContext();
    const [guide, setGuide] = useState(null);
    const { logEvent } = useWebAnalyticsContext();

    useEffect(() => {
        const fullGuideName = buildGuideName(GuideKeys.WebOnlineSafetyKey, c);
        const g = getGuide(0, fullGuideName);
        setGuide(g);

        logEvent(Events.GuideOpen, Origins.Guide, Templates.guide.open(fullGuideName));
    }, []);

    useEffect(() => {
        setDocumentTitle('Minimize Risk. Online Safety Guide: Bright Sky');
    }, [setDocumentTitle]);

    const guideContent = getContent(StorageKeys.GuideKey, 'Guide_WebOnlineSafety');

    useEffect(() => {
        const hasContent = content != null && content[StorageKeys.GuideKey] != null;
        if (hasContent) {
            const innerContent = guideContent?.Guides ? guideContent?.Guides : [];
            setItems(innerContent);
        }
    }, [content, loaded, setItems, language]);

    const guideTitle = guideContent.Title;
    const guideDescription = guideContent.Description;

    const mappedGuides = items.map((v, i) => {
        const isEven = i % 2 !== 1;
        const hasLearnMore = v?.LearnMore != null && v?.LearnMore != '';
        const learnMoreContent = <p dangerouslySetInnerHTML={{ __html: decode(v.LearnMore) }} />;

        return (
            <div key={i.toString()} className="guide">
                <h4>{decode(v.Title)}</h4>
                <div className="guide-content">
                    {isEven && <div className="guide-text left" dangerouslySetInnerHTML={{ __html: decode(v.Content) }} />}
                    {!isEven && <div className="guide-text left" dangerouslySetInnerHTML={{ __html: decode(v.Content) }} />}
                </div>
                <div>
                    {hasLearnMore && (
                        <span>
                            <ShowMoreShowLess binding1={'Text_ShowMore.Text'} binding2={'Text_ShowLess.Text'} limit={0}>
                                {learnMoreContent}
                            </ShowMoreShowLess>
                            {showLearnMore}
                        </span>
                    )}
                </div>
                <BackToTop />
            </div>
        );
    });

    return (
        <div className="page-isitabuse">
            <LocateSupportBanner />
            <PagePanel id="skip-to" background="#FFFFFF">
                <div className="page-title">
                    <h3>{decode(guideTitle)}</h3>
                </div>
                <TextBlock content={decode(guideDescription)} />
            </PagePanel>
            <PagePanel background="#FFFFFF">{mappedGuides}</PagePanel>
        </div>
    );
};

export default OnlineSafetyPage;
