import { i18nService, StorageKeys } from 'brightsky-3';
import React, { useState, createContext, useEffect } from 'react';
import useContentContext from '../hooks/useContentContext';
import useConfig from '../../../config/hooks/useConfig';
import { useTranslation } from 'react-i18next';
import http from 'brightsky-3/services/httpRequestService';
import { getCountrySubdomain, getLangFromUrl, hasCountryInDomain } from '../../../util/functions';

const initialState = {
    country: null,
    language: null,
};

export const ConfigContext = createContext(initialState);

export const ConfigContextProvider = ({ children }) => {
    const [country, setCountry] = useState('VF');
    const [language, setLanguage] = useState();
    const [languages, setLanguages] = useState([]);
    const [themes, setThemes] = useState(false);
    const [theme, setTheme] = useState();
    const [hasConfirmedConfig, setConfirmedConfig] = useState(false);
    const [loading, setLoading] = useState(false);
    const { content, getRawContent, loaded } = useContentContext();
    const config = useConfig();
    const { i18n } = useTranslation();

    const urlLang = getLangFromUrl();
    const hasLangInUrl = urlLang != null && urlLang !== '' && urlLang != undefined;

    const subdomain = getCountrySubdomain();
    const hasCountryInUrl = hasCountryInDomain();

    // THEME
    useEffect(() => {
        setLoading(true);
        http.get(
            `${config.apiUrl}content/themes`,
            themes => {
                const mappedThemes = themes.data.map(t => {
                    return {
                        country: t.Country.iv,
                        primary: t.Primary.iv,
                        secondary: t.Secondary.iv,
                        text: t.Text.iv,
                        headerandlinks: t.HeaderAndLinks.iv,
                        hover: t.Hover.iv,
                        icons: t.Icons.iv,
                        exit: t.Exit.iv,
                        page: t.Page.iv,
                    };
                });

                setThemes(mappedThemes);
                setLoading(false);
                const currentTheme = mappedThemes.filter(t => t.country === country)[0];
                setTheme(currentTheme);
            },
            err => {
                console.log('ERROR: Unable to load themes: ', err);
            }
        );
    }, [setLoading, setThemes, country]);

    // NOTE: After the new country is selected, we need to reload the translations
    //       and dynamically retrieve the theme
    useEffect(() => {
        if (country != null) {
            const sCountry = getCountrySubdomain();
            const calcCountry = sCountry != null ? sCountry : country;
            i18nService.LoadWebTranslations(config.apiUrl, calcCountry, urlLang, translations => {
                for (const [key, value] of Object.entries(translations)) {
                    i18n.addResourceBundle(key, 'translation', value.translation);
                }
            });
            if (window.less && themes) {
                const currentTheme = themes.filter(t => t.country === calcCountry)[0];
                setTheme(currentTheme);
                window.less
                    .modifyVars({
                        '@primary-color': currentTheme.primary,
                        '@secondary-color': currentTheme.secondary,
                        '@text-color': currentTheme.text,
                        '@headerandlinks-color': currentTheme.headerandlinks,
                        '@hover-color': currentTheme.hover,
                        '@icons-color': currentTheme.icons,
                        '@exit-color': currentTheme.exit,
                    })
                    .catch(error => {
                        console.error('ERROR: Could not apply theme', error);
                    });
            }
        }
    }, [country, themes]);

    // COUNTRY & LANG
    useEffect(() => {
        const sCountry = getCountrySubdomain();
        if (subdomain != null && subdomain.toUpperCase() !== country && hasCountryInUrl) {
            setConfirmedConfig(true);
            setCountry(sCountry);
        }
        if (hasLangInUrl) {
            setLanguage(urlLang);
        }
    }, []);

    // LANGUAGE LIST
    useEffect(() => {
        const hasContent = content != null && content[StorageKeys.CountryStore] != null;
        if (hasContent) {
            setLanguages(getLanguagesByCountry(country));
        }
    }, [country, loaded]);

    const getLanguagesByCountry = countyCode => {
        const countryContent = getRawContent(StorageKeys.CountryStore);
        const selectedCountry = countryContent.filter(c => c.Code.iv === countyCode);
        if (selectedCountry.length > 0) {
            const mappedLanguages = selectedCountry[0].Languages.iv.map(l => {
                return {
                    value: l.Code,
                    label: l.Language,
                };
            });
            return mappedLanguages;
        }
        return [];
    };

    // WEB REDIRECT DETAILS
    const getWebRedirectByCountry = countyCode => {
        const countryContent = getRawContent(StorageKeys.CountryStore);
        const selectedCountry = countryContent.filter(c => c.Code.iv === countyCode);
        if (selectedCountry.length > 0) {
            return {
                isWebRedirect: selectedCountry[0].WebRedirect ? selectedCountry[0].WebRedirect.iv : false,
                WebRedirectURL: selectedCountry[0].RedirectURL ? selectedCountry[0].RedirectURL.iv : null,
            };
        }
        return {};
    };

    return (
        <ConfigContext.Provider
            value={{
                country,
                setCountry,
                language,
                setLanguage,
                getLanguagesByCountry,
                languages,
                hasConfirmedConfig: hasConfirmedConfig || (hasLangInUrl && hasCountryInUrl),
                setConfirmedConfig,
                themes,
                theme,
                loading,
                getWebRedirectByCountry,
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
};
