import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { ContentStorageKeys, useCountryTranslation } from 'brightsky-3';
import http from 'brightsky-3/services/httpRequestService';
import useConfig from '../../../config/hooks/useConfig';
import useActiveCountry from '../hooks/useActiveCountry';
import useConfigContext from '../hooks/useConfigContext';
import { getCountrySubdomain } from '../../../util/functions';

const initialState = {
    content: {},
};

export const ContentContext = createContext(initialState);

export const ContentContextProvider = ({ children }) => {
    const [currentContent, setCurrentContent] = useState();
    const [loaded, setLoaded] = useState();
    const [contentCachedCount, setContentCachedCount] = useState(0);
    const config = useConfig();
    const c = useActiveCountry();
    const sCountry = getCountrySubdomain();
    const calcCountry = sCountry == null ? c : sCountry;
    const { bind, l, n } = useCountryTranslation(calcCountry);
    const { country } = useConfigContext();

    const contentToCacheCount = ContentStorageKeys.length - 1;

    useEffect(() => {
        if (contentToCacheCount === contentCachedCount) {
            setLoaded(true);
        }
    }, [contentCachedCount]);

    useEffect(() => {
        setCurrentContent();
        setContentCachedCount(0);

        const urlCountry = getCountrySubdomain();
        const calcCountry = urlCountry != null ? urlCountry : country;
        const fetchContent = async () => {
            try {
                const ctx = {};
                let count = 0;
                ContentStorageKeys.forEach(async storageKey => {
                    const countryFilter = storageKey.name !== 'Countries' ? `?country=${calcCountry}` : '';
                    await http.get(
                        `${config.apiUrl}content/${storageKey.name}${countryFilter}`,
                        result => {
                            ctx[storageKey.key] = result.data;
                            setCurrentContent(ctx);
                            setContentCachedCount(count++);
                        },
                        err => {
                            console.warn('ContentContext: Unable to retrieve data', storageKey, config.apiUrl);
                        }
                    );
                });
            } catch (ex) {
                console.error(ex);
            }
        };

        fetchContent();
    }, [setCurrentContent, setContentCachedCount, country]);

    const getContent = (key, binding) => {
        const selected = getRawContent(key);
        const b = bind(binding);
        return selected[l][n][b];
    };

    const getRawContent = key => {
        const selected = currentContent[key];
        if (selected == null) {
            throw `ContentContext: No content found matching ${key} binding`;
        }

        return selected;
    };

    const hasContentSet = key => {
        return currentContent != null && currentContent[key];
    };

    return (
        <ContentContext.Provider value={{ hasContentSet, content: currentContent, getContent, getRawContent, loaded }}>
            {children}
        </ContentContext.Provider>
    );
};

ContentContextProvider.propTypes = {
    children: PropTypes.node,
};
