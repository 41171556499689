import React, { useEffect, useState } from 'react';
import DefaultModal from '../shared/DefaultModal';
import { Col, Form, Row, Select, Spin } from 'antd';
import DefaultSelect from '../shared/DefaultSelect';
import BoundButton from '../shared/BoundButton';
import { decode } from 'html-entities';
import CountrySelect from '../shared/CountrySelect';
import useConfigContext from '../shared/hooks/useConfigContext';
import { useHistory } from 'react-router-dom';
import { Events, Templates, useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import useConfig from '../../config/hooks/useConfig';
import useWebAnalyticsContext from '../shared/hooks/useWebAnalyticsContext';
import useAppSettings from '../shared/hooks/useAppSettings';

const ConfigPage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { logEvent, consumerId } = useWebAnalyticsContext();
    const history = useHistory();
    const config = useConfig();
    const [form] = Form.useForm();
    const [hasSelectedCountry, setHasSelectedCountry] = useState(false);
    const [hasSelectedLanguage, setHasSelectedLanguage] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(false);
    const [countryRedirect, setRedirectURL] = useState({ isWebRedirect: false, WebRedirectURL: null });
    const [languagesList, setLanguagesList] = useState([]);
    const { setCountry, language, setLanguage, getLanguagesByCountry, hasConfirmedConfig, setConfirmedConfig, getWebRedirectByCountry } =
        useConfigContext();
    const { appSettings, getAppSettings, loaded } = useAppSettings();
    const countryisNullOrVF = c == null || c === 'VF';

    // NOTE: If we detect the user has directly accessed a country's URL
    //       we will want to automatically set the language and direct them
    //       to the landing page.
    useEffect(() => {
        if (hasConfirmedConfig) history.push(`/${language}/home`);
    }, [hasConfirmedConfig, language]);

    useEffect(() => {
        if (selectedCountry) getAppSettings(selectedCountry);
    }, [selectedCountry]);

    useEffect(() => {
        if (appSettings?.defaultLanguageCode) {
            setLanguage(appSettings.defaultLanguageCode);
            form.setFieldsValue({ language: appSettings.defaultLanguageCode });
            setHasSelectedLanguage(true);
        }
    }, [appSettings]);

    const modalTitle = countryisNullOrVF ? 'Website Settings' : decode(ct('Web_Config_Title_WebsiteSettings.Text'));
    const countrySelectLabel = countryisNullOrVF ? 'Select Country' : decode(ct('Web_Label_SelectCountry.Text'));
    const languageSelectLabel = countryisNullOrVF ? 'Select Language' : decode(ct('Web_Label_SelectLanguage.Text'));
    const goToWebsiteButton = countryisNullOrVF ? 'Go To Website' : decode(ct('Web_Button_GoToWebsite.Text'));

    const onCountrySelect = v => {
        const c = form.getFieldValue('country');
        const currentLangs = getLanguagesByCountry(c);
        const webRedirect = getWebRedirectByCountry(c);
        setLanguagesList(currentLangs);
        setSelectedCountry(c);
        setRedirectURL(webRedirect);
        if (c != null) setHasSelectedCountry(true);
        else setHasSelectedCountry(false);
        logEvent(Events.SettingsChange, 'ConfigScreen_Web', Templates.settings.change('country', c));
    };

    const onLanguageSelect = l => {
        setLanguage(l);
        setHasSelectedLanguage(true);
        logEvent(Events.SettingsChange, 'ConfigScreen_Web', Templates.settings.change('language', l));
    };

    const onConfirm = () => {
        if (countryRedirect.isWebRedirect) {
            window.location.href = countryRedirect.WebRedirectURL;
        } else {
            setConfirmedConfig(true);
            if (config.isLocalhost == null) {
                const countryUrl = config.baseCountryUri.replace('{c}', selectedCountry.toLowerCase()).replace('{l}', language);
                window.location.href = `${countryUrl}/${consumerId}`;
            } else {
                setCountry(selectedCountry);
            }
        }
    };

    const formValid = hasSelectedCountry && hasSelectedLanguage;

    const footer = (
        <Row>
            <Col style={{ textAlign: 'right' }} span={24}>
                <BoundButton onClick={onConfirm} text={goToWebsiteButton} disabled={!formValid && !countryRedirect.isWebRedirect} />
            </Col>
        </Row>
    );

    return (
        <div className="config">
            {hasConfirmedConfig && <Spin size="large" style={{ marginTop: 50, height: '100vh' }} />}
            {!hasConfirmedConfig && (
                <DefaultModal visible title={modalTitle} footer={footer} width={700}>
                    <Form form={form} layout="vertical">
                        <Form.Item label={countrySelectLabel} name="country" required>
                            <CountrySelect size="large" onChange={onCountrySelect} />
                        </Form.Item>
                        {hasSelectedCountry && loaded && language && !countryRedirect.isWebRedirect && (
                            <Form.Item label={languageSelectLabel} name="language" required>
                                <DefaultSelect size="large" onChange={onLanguageSelect} items={languagesList} />
                            </Form.Item>
                        )}
                    </Form>
                </DefaultModal>
            )}
        </div>
    );
};

export default ConfigPage;
