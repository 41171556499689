import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { Events, Origins, useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../hooks/useActiveCountry';
import http from 'brightsky-3/services/httpRequestService';
import useConfig from '../../../config/hooks/useConfig';
import { getConsumerIdFromUrl } from '../../../util/functions';
import { getCountrySubdomain } from '../../../util/functions';

const initialState = { consumerId: null };

export const WebAnalyticsContext = createContext(initialState);

export const WebAnalyticsContextProvider = ({ children }) => {
    const { l } = useCountryTranslation();
    let [consumerId, setConsumerId] = useState();
    const [registering, setRegistering] = useState(false);
    const [loggedStartup, setLoggedStartup] = useState(false);
    const [consumerOverrided, setConsumerOverrided] = useState(false);
    const c = useActiveCountry();
    const config = useConfig();

    const cacheConsumerId = newConsumerId => {
        setConsumerId(newConsumerId);
    };

    const registerConsumer = () => {
        const urlConsumer = getConsumerIdFromUrl();
        const urlCountry = getCountrySubdomain();

        if (urlConsumer !== null && urlConsumer !== undefined && urlConsumer !== '') {
            setRegistering(false);
        } else {
            setRegistering(true);
            let countryInUrl = urlCountry == null ? 'VF' : urlCountry;
            const consumerData = {
                countryCode: countryInUrl,
                deviceType: 'Web',
                applicationVersion: 'Web',
                coverMode: 'NA',
                languageCode: l,
            };

            http.post(
                `${config.apiUrl}Analytics/Register`,
                consumerData,
                newConsumer => {
                    cacheConsumerId(newConsumer.data.Id);
                    setRegistering(false);
                },
                err => {
                    console.log(err);
                }
            );
        }
    };

    const overrideConsumer = NewConsumerId => {
        if (NewConsumerId !== null && NewConsumerId !== undefined && NewConsumerId !== '') {
            setConsumerId(NewConsumerId);
            setConsumerOverrided(true);
        } else {
            setConsumerOverrided(false);
        }
    };

    const logEvent = (event, origin, log) => {
        if (consumerId) {
            const stringLog = log ? JSON.stringify(log) : log;
            const eventLog = {
                consumerId,
                type: event.type,
                subType: event.subType,
                origin,
                market: c,
                log: stringLog,
            };

            http.post(
                `${config.apiUrl}Analytics/Event`,
                eventLog,
                e => { }, // We want to fire & forget logs to avoid a performance impact
                err => {
                    console.log(err);
                }
            );
        }
    };

    // Logging startup event
    useEffect(() => {
        if (consumerId && !loggedStartup) {
            logEvent(Events.AppOpen, Origins.App, { EnterWebsite: c });
            setLoggedStartup(true);
        }
    }, [consumerId]);

    useEffect(() => {
        const fetchConsumerId = async () => {
            if (!consumerId && !registering && !consumerOverrided) {
                registerConsumer();
            }
            if (!consumerOverrided) setConsumerId(consumerId);
        };

        fetchConsumerId();
    }, [setConsumerId]);

    return (
        <WebAnalyticsContext.Provider value={{ consumerId, registerConsumer, logEvent, overrideConsumer }}>{children}</WebAnalyticsContext.Provider>
    );
};

WebAnalyticsContextProvider.propTypes = {
    children: PropTypes.node,
};
