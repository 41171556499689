import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StorageKeys } from 'brightsky-3';
import { decode } from 'html-entities';
import './css/boundaccordion.less';
import useContentContext from './hooks/useContentContext';
import DefaultSelect from './DefaultSelect';

const CountrySelect = ({ onChange, size, width, className, value, allowClear = true }) => {
    const { content, getRawContent, loaded } = useContentContext();
    const [items, setItems] = useState([]);

    useEffect(() => {
        const hasContent = content != null && content[StorageKeys.CountryStore] != null;
        if (hasContent) {
            const countryContent = getRawContent(StorageKeys.CountryStore);
            const countryList = countryContent
                .filter(c => c.WebEnabled.iv === true)
                .sort((a, b) => {
                    const ta = a.Name.en.toLowerCase();
                    const tb = b.Name.en.toLowerCase();
                    return ta < tb ? -1 : ta > tb ? 1 : 0;
                })
                .map(c => {
                    return {
                        value: c?.Code?.iv,
                        label: decode(c?.Name?.en),
                    };
                });
            setItems(countryList);
        }
    }, [content, loaded, setItems]);

    const loading = items == null || items.length === 0;

    return (
        <DefaultSelect
            size={size}
            width={width}
            value={value}
            allowClear={allowClear}
            className={className}
            items={items}
            loading={loading}
            onChange={onChange}
        />
    );
};

CountrySelect.propTypes = {
    size: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.any,
    allowClear: PropTypes.bool,
};

export default CountrySelect;
